//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { sysMachineQuery } from '@/api/modular/system/machineManage'
export default {
  data () {
    return {
      loading: true,
      sysOsInfo: [],
      sysJavaInfo: [],
      sysJvmMemInfo: []
    }
  },
  // 進頁面加載
  created () {
    this.loadDataList()
  },
  methods: {
    // 加載數據方法
    loadDataList () {
      sysMachineQuery().then((res) => {
        this.loading = false
        this.sysOsInfo = res.data.sysOsInfo
        this.sysJavaInfo = res.data.sysJavaInfo
        this.sysJvmMemInfo = res.data.sysJvmMemInfo
      })
    }
  }

}
